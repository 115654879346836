import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "yob", "dob", "yod", "dod" ]

  initialize() {
    var dob_selector = document.querySelectorAll('#member_date_of_birth_exact')[0];
    var dod_selector = document.querySelectorAll('#member_date_of_death_exact')[0];

    this.woof(dob_selector.checked, this.yobTarget, this.dobTarget)
    this.woof(dod_selector.checked, this.yodTarget, this.dodTarget)
  }

  dob_toggle(e) {
    this.woof(e.currentTarget.checked, this.yobTarget, this.dobTarget)
  }

  dod_toggle(e) {
    this.woof(e.currentTarget.checked, this.yodTarget, this.dodTarget)
  }

  woof(checked, y, d) {
    if (checked) {
      d.style.display = '';
      y.style.display = 'none';
    } else {
      d.style.display = 'none';
      y.style.display = '';
    }
  }
}
